import { InstagramOutlined } from '@ant-design/icons';

export const Footer = () => {
  return (
    <footer>
      <div className='h-12 text-2xl text-center'>
        <a
          href='https://www.instagram.com/sallyhryu/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <InstagramOutlined />
        </a>
      </div>
    </footer>
  );
};
