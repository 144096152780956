// import React, { useEffect, useRef, useState } from 'react';
// import { motion } from 'framer-motion';

// import dood from '../images/dood.png';

export const ComingSoon = () => {
  // const doodRef = useRef(null);
  // const [constraints, setConstraints] = useState({ left: 0, right: 0 });

  // useEffect(() => {
  //   const updateConstraints = () => {
  //     const doodWidth = doodRef.current.offsetWidth;
  //     const windowWidth = window.innerWidth;
  //     const leftRightConstraint = (windowWidth - doodWidth) / 2 - 50;

  //     setConstraints({
  //       top: -200,
  //       bottom: 200,
  //       left: -leftRightConstraint,
  //       right: leftRightConstraint,
  //     });
  //   };

  //   updateConstraints();
  //   window.addEventListener('resize', updateConstraints);

  //   return () => window.removeEventListener('resize', updateConstraints);
  // }, []);

  return (
    <main>
      <div
        id='intro'
        className='flex justify-center items-center h-almost-screen overflow-hidden'
      >
        <h1 className='text-8xl'>matte</h1>
        {/* <a href='/'>coming soon</a> */}
        {/* <motion.div
          drag
          dragConstraints={constraints}
          animate={{
            scale: [1, 1.05, 1],
            rotate: [0, 0, 0, 0, 1, -1, 0],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          ref={doodRef}
        >
          <a href='/'>
            <img src={dood} alt='dood' className='dood h-72' id='dood'></img>
          </a>
        </motion.div> */}
      </div>
    </main>
  );
};
