import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
// import { useMediaQuery } from 'react-responsive';

import './App.css';
// import { Header } from './components/Header';
// import { HamburgerHeader } from './components/HamburgerHeader';
// import { About } from './pages/About.jsx';
// import { Home } from './pages/Home';
import { Footer } from './components/Footer';
import epicConsoleLogger from './utils/epicConsoleLogger.js';
import { ComingSoon } from './pages/ComingSoon.jsx';

function App() {
  // const isMobile = useMediaQuery({ maxWidth: 890 });

  useEffect(() => {
    epicConsoleLogger();
  }, []);

  useEffect(() => {
    const defaultCursor = 'images/cursors/default.png';
    const clickCursor = 'images/cursors/click.png';

    const handleMouseDown = () => {
      document.body.style.cursor = `url('${clickCursor}'), auto`;
    };

    const handleMouseUp = () => {
      document.body.style.cursor = `url('${defaultCursor}'), auto`;
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <div className='min-h-screen flex flex-col justify-between'>
      {/* {isMobile ? <HamburgerHeader /> : <Header />} */}
      <Routes>
        <Route path='/' element={<ComingSoon />} />
        {/* <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} /> */}
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
